import logo from './logo.svg';
import './App.css';


import QRCode from 'qrcode'
import { useState } from 'react'
import amgenlogo from './assets/amgen.png';

function App() {

  const [url, setUrl] = useState('https://contents-amgen.com/prd/user-screen.html?content_id=325')
	const [qr, setQr] = useState('')
	const GenerateQRCode = () => {
		QRCode.toDataURL(url, {
			width: 800,
			margin: 2,
			color: {
				dark: '#335383FF',
				light: '#EEEEEEFF'
			}
		}, (err, url) => {
			if (err) return console.error(err)

			console.log(url)
			setQr(url)
		})
	}

  return (
    <div className="app">

    <img src ={amgenlogo}   style={{ width: '120px', height: '30px' }}  /> 
          <h1> Publication Content Delivery Portal (PCDP) -  QR Code POC [React JS] - </h1>
                <h4>Aim: To simplify PCDP application design to address Security Vulnerabilities </h4>
    
              Type Content URL -  
          <input 
            type="text"
               
            placeholder="e.g. https://contents-amgen.com/prd/user-screen.html?content_id=325"
            value={url}
            onChange={e => setUrl(e.target.value)} />
          <button class="button button1" onClick={GenerateQRCode}>Generate</button>
          {qr && <>
    
                    <a href={url} target="_blank">
            <img src={qr} />       </a>
            <a href={qr} download="pcdp-code.png">Scan QR Code for Document Download</a>               
          </>}
      
        </div>
  );
}

export default App;
